#landingprivacy {
  position: relative;
  height: auto;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}
#landingprivacy .left-dot-landingprivacy {
  max-height: 100px;
  position: absolute;
  left: 0;
  width: 450px;
}
#landingprivacy .right-dot-landingprivacy {
  max-height: 100px;
  position: absolute;
  right: 0;
  width: 450px;
  top: 70px;
}

#landingprivacy img {
  height: 100%;
  width: 100%;
}
.landingprivacy-title-group {
  display: flex;
  justify-content: center;
  padding-top: 80px;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.landingprivacy-title-group .dtitle {
  font-size: 45px;
  font-weight: 700;
  font-family: "open sans";
  letter-spacing: 1px;
  line-height: 40px;
}
.pwsub {
  font-size: 25px;
  letter-spacing: 1px;
  padding-top: 20px;
  font-weight: 400;
  padding-bottom: 55px;
}
.ppc-container {
  background-color: #0f9b51;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 2rem;
  gap: 4rem;
  width: 90%;
  /* flex: 1; */
}
#pp-policy-round {
  border-radius: 50%;
  /* border: 2px solid #0f9b51; */
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 20px; */
  background-color: white;
}
#p-greencoloricon {
  height: 60px;
  width: 60px;
  color: #0f9b51;
}
.p-head {
  font-size: 25px;
  font-weight: bold;
  color: white;
  font-family: "open sans";
}
#p-greencoloricon path {
  stroke: #0f9b51 !important;
}
.p-subhead {
  font-size: 18px;
  font-weight: 400;
  color: white;
  font-family: "open sans";
}
@media only screen and (min-width: 1199px) and (max-width: 1229px) {
  #landingprivacy .left-dot-landingprivacy {
    width: 350px;
  }
  #landingprivacy .right-dot-landingprivacy {
    width: 250px;
    top: 46px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1199px) {
  #landingprivacy .left-dot-landingprivacy {
    width: 300px;
  }
  #landingprivacy .right-dot-landingprivacy {
    width: 300px;
    top: 46px;
  }
}
@media only screen and (min-width: 599px) and (max-width: 992px) {
  #landingprivacy-greencoloricon {
    height: 35px;
    width: 35px;
  }
  .whatwedon-title-group {
    padding-top: 13px;
  }
  .whatwedon-title-group .dtitle {
    font-size: 30px;
    /* letter-spacing: 0; */
    /* margin-bottom: 0; */
  }
  .whatwedon-title-group .pwsub {
    font-size: 20px;
    padding: 12px;
    text-align: center;
  }
  #landingprivacy .left-dot-landingprivacy {
    width: 200px;
  }
  #landingprivacy .right-dot-landingprivacy {
    width: 200px;
    top: 46px;
  }
}

/* 
      Small Screen Size
  */
#landingprivacy .plkgapbetweencontainer{
  margin-bottom: 55px;
}
  
@media screen and (max-width: 599px) {
  .landingprivacy-title-group {
    padding-top: 13px;
  }
  .landingprivacy-title-group .dtitle {
    font-size: 28px;
    letter-spacing: 0;
    margin-bottom: 0;
  }
  .landingprivacy-title-group .pwsub {
    font-size: 18px;
    padding: 12px;
    text-align: center;
  }
  .three-simple-step {
    font-size: 20px;
  }
  #landingprivacy .left-dot-landingprivacy {
    width: 125px;
    height: 30px;

  }
  #landingprivacy .right-dot-landingprivacy {
    width: 125px;
    top: 46px;
    height: 30px;

  }
  #landingprivacy-greencoloricon {
    height: 25px;
    width: 25px;
  }
  .ppc-container{
    padding:3rem 2.5rem
  }

  .partners {
    margin: 0px 0px 100px 0px !important;
  }
}
@media only screen and (min-width: 200px) and (max-width:991px){
  #landingprivacy{
    padding-bottom: 0;
  }
  #landingprivacy .plkgapbetweencontainer{
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .extrapbcalc{
    padding-bottom: calc(2rem + 20px)!important;
  }
}

.line {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
}

.line > hr {
  width: 100%;
  height: 0px;
}

.line > p {
  padding: 55px 0px !important
}

.partners {
  margin: 55px 0px 100px 0px;
}
