:root {
  --primaryColor: #0f9b51;
}

.container {
  display: flex;
  padding: 40px 140px;
  background-color: var(--primaryColor);
}
.container h2 {
  margin: 0 0 12px 0;
  font-size: 28px;
  font-family: "open sans";
}
.fottmenutitle{
  font-size: 28px!important;
}
.container p {
  font-size: 15px;
  text-align: left;
  font-family: "open sans" ;

  /* font-family: "Montserrat", sans-serif !important; */
}
.mapContainer {
  width: 100%;
  padding: 20px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: center;
}
.mapContainer iframe {
  height: 300px;
  width: 100%;
}

.contactContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 50px;
  color: white;
  font-family:"open sans" ;
  /* height: 85%; */
  margin: 0 auto;
}

.contactContainer h3 {
  font-size: 3rem;
  color: inherit;
  font-family: "open sans" ;

  /* font-family: "Montserrat", sans-serif; */
}

.contactContainer > div {
  font-size: 15px;
  text-align: left;
  font-family: "open sans";

  /* font-family: "Montserrat", sans-serif; */
}

.contactSubContainer > div {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  font-size: 16px;
  text-align: left;
  font-family: "open sans" ;

  /* font-family: "Montserrat", sans-serif; */
}

.contactSubContainer > div > span {
  margin-right: 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}

.contactSubContainer > p {
  font-family: "open sans";

  /* font-family: "Montserrat", sans-serif; */
  font-size: 16px;
  text-align: left;
}

.socialNetworkingContainer ul {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  width: 280px;
  margin: 0;
  padding: 10px 0;
}

.socialNetworkingContainer ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.socialNetworkingContainer ul li a {
  font-size: 2.2rem;
  color: var(--primaryColor);
}

.socialNetworkingContainer ul li:hover {
  background-color: var(--primaryColor);
  border: 2px solid white;
}

.socialNetworkingContainer ul li:hover a {
  color: white;
}

.socialNetworkingContainer ul li a:hover {
  color: white;
}
.mobilmenumb{
  margin-bottom: 25px;
  display:none;
}
.hideit{
  display: none;
}
@media only screen and (max-width: 1400px) {
  .container {
    padding: 40px;
  }
}
.contactTitle {
  margin-top: 12px;
  text-transform:capitalize
}

@media (min-width: 992px) and (max-width: 1200px) {
  .mapContainer {
    width: 100%;
  }
  .contactTitle {
    margin: 0;
  }
}
@media only screen and (max-width: 500px) {
  .mapContainer iframe {
    height: 250px !important;
    width: 250px !important;
  }
}
@media only screen and (max-width:765px){
  .mobilmenumb{
    margin-bottom: 25px;
    border-bottom: 1px solid white;
    display: block;
  }
  .secondThirdMenu{
    margin-top: 25px;
  }
  .hideit{
    display: block
  }
  .lastpad{
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 992px) {
  .container {
    flex-direction: column-reverse;
    padding: 40px 60px;
  }
  .mapContainer {
    width: 100%;
    margin-bottom: 5px;
  }
  .contactContainer {
    /* margin-left: 20px; */
    /* margin-bottom: 20px; */
    height: 350px;
  }
 
  .contactContainer h3 {
    margin-bottom: 20px;
  }
  .socialNetworkingContainer {
    width: 50%;
  }
  .contactTitle {
    margin-top: 0;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 30px;
  }
  .contactTitle {
    margin-top: 0;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 15px;
  }
  .mapContainer iframe {
    height: 300px !important;
    width: 300px !important;
  }
  .contactTitle {
    margin-top: 0;
  }
}
.privacPolicyTermsOfUse {
  display: flex;
  /* justify-content: space-around; */
  justify-content: flex-start;
  width: 100%;
  margin-top: 12px;
  gap: 25px;
}
.privacPolicyTermsOfUse a {
  color: white !important;
}
.whiteHoverline {
  border-bottom: 1px solid #0f9b51;
}
.whiteHoverline:hover {
  border-bottom: 1px solid white;
}
.footermenus ul{
  list-style: none;
  padding-left: 0;
}
.footermenus a{
  color: white!important;
  font-size: 16px;
}
.footermenus p{
  font-size: 20px;
  font-weight: 700;
  font-family: "open sans" ;

  /* font-family: "Montserrat", sans-serif !important; */
}
.footermenus a:hover{
  color: white!important;
}
.footermenus {
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  margin-left: 50px;
  color: white;
  font-family: "open sans";

  /* font-family: "Montserrat", sans-serif; */
  /* height: 85%; */
  width: 100%;
  margin-left: 0;
  /* margin: 0 auto; */
}
#seconMenuIdContact li a{
  position: relative;
}
#seconMenuIdContact li a::after{
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: white;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
#seconMenuIdContact li a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.twitter_icon{
  height: 18px;
  width: 22px;
  object-fit: contain;
  color: green;
}
.twitter_main_div:hover .twitter_icon{
  color:white;
}