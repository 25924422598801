#librafooter{
	/* background-color: #a6dfff; */
	bottom: 0;
	/* height: 40px; */
  padding: 10px 0;
	left: 0;
	position: fixed;
	width: 100%;
    background-color: #F7F7F7;
    z-index: 99;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  line-height:2.8em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.powered_by{
    font-size: 12px;
    font-weight: 600;
    font-family: 'Open Sans';
    margin-right: 6px;
}

.libra_footer_title {
    font-size: 16px;
    color: #1F2F5D;
    text-transform: uppercase;
  }
  .logo {
    height: 30px;
    width: 30px;
    margin-right: 6px;
  }