#howitworks {
  position: relative;
  height: auto;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}
#howitworks .left-dot-howitworks {
  max-height: 100px;
  position: absolute;
  left: 0;
  width: 450px;
}
#howitworks .right-dot-howitworks {
  max-height: 100px;
  position: absolute;
  right: 0;
  width: 450px;
  top: 55px;
}

#howitworks .slider-imge-div  img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
#howitworks .howitworks-title-group {
  display: flex;
  justify-content: center;
  padding-top: 45px;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
#howitworks .howitworks-title-group .dtitle {
  font-size: 45px;
  font-weight: 700;
  font-family: "open sans";
  letter-spacing: 1px;
  line-height: 60px;
}
#howitworks .wsub {
  font-size: 25px;
  letter-spacing: 1px;
  width: 70%;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 75px;
}

#howitworks .swiper-button-prev::after {
  color: #0f9b51;
}
#howitworks .swiper-button-next::after {
  color: #0f9b51;
}
#howitworks .swiper-pagination-bullet-active {
  background-color: #0f9b51;
}
/* .slider-imge-div {
  max-height: 350px;
} */
#howitworks .showflex{
    display: flex;
}
/* .workitme-h4{
	position: relative;
} */
#howitworks .workitme-h4 {
  display: inline-block;
  padding: 0 15px;
  position: relative;
  z-index: 1;
  font-size: 25px;
  font-weight: 700;
  opacity: 0.5;
}
#howitworks .activeworkitemm {
  opacity: 1;
  color: #0f9b51;
}
#howitworks .straight-line {
  border-bottom: 1px solid #0f9b51;
  flex: 1;
}
#howitworks .padding3rem {
  padding-bottom: 105px;
}
#howitworks .kkldjfkldjjkgfkgk{
  padding-bottom: 5rem;
}
@media only screen and (min-width: 1199px) and (max-width: 1229px) {
  #howitworks .left-dot-howitworks {
    width: 350px;
  }
  #howitworks .right-dot-howitworks {
    width: 250px;
    top: 46px;
  }
  #howitworks .workitme-h4 {
    font-size: 24px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1199px) {
  #howitworks .left-dot-howitworks {
    width: 300px;
  }
  #howitworks .right-dot-howitworks {
    width: 300px;
    top: 46px;
  }
  #howitworks .workitme-h4 {
    font-size: 20px;
  }
}
@media only screen and (min-width: 599px) and (max-width: 992px) {
  #howitworks .swiper-wrapper{
    height: 100%;
  }
  #howitworks .straight-line {
    display: none;
  }
  #howitworks .swiper-button-next{
    display: none;
  }
  #howitworks .swiper-button-prev{
    display: none;
  }
  #howitworks .vertical-line {
    display: flex !important;
    justify-content: center;
    align-items: center;
    border-left: 1px solid green;
    height: 30px;
    margin: 0 auto;
    width: 0;
  }
  #howitworks .uifkdjfkd {
    justify-content: center;
  }
  #howitworks-greencoloricon {
    height: 35px;
    width: 35px;
  }
  #howitworks .whatwedon-title-group {
    padding-top: 13px;
  }
  #howitworks .whatwedon-title-group .dtitle {
    font-size: 30px;
    /* letter-spacing: 0; */
    /* margin-bottom: 0; */
  }
  #howitworks .whatwedon-title-group .wsub {
    font-size: 20px;
  }
  #howitworks .left-dot-howitworks {
    width: 200px;
  }
  #howitworks .right-dot-howitworks {
    width: 200px;
    top: 46px;
  }
}

/* 
    Small Screen Size
*/


#howitworks .vertical-line {
  display: none;
}

#howitworks .swiper {
  max-height: 460px;
}
#howitworks .swiper-slide {
  max-height: 460px;
  display: flex;
  align-items: center;
  padding:0 2rem
}
#howitworks .slider-imge-div {
  width: 800px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border: 1px solid;
  border-radius: 10px;
  box-shadow: #0f9b51 0px 1px 2px 0px, #0f9b51 0px 1px 3px 1px;
  max-height: 400px;
  /* padding: 1px; */
}


#howitworks .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
  bottom: 1px;
}
#howitworks .swiper-button-next:after {
  font-size: 17px !important;
  border: 1px solid #0f9b51;
  border-radius: 50%;
  padding: 10px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
#howitworks .swiper-button-next,
.swiper-button-prev {
  width: auto !important;
}
.swiper-button-prev:after {
  font-size: 17px !important;
  border: 1px solid #0f9b51;
  border-radius: 50%;
  padding: 10px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
@media screen and (max-width:748px) {
  #howitworks .slider-imge-div{
    max-height: 360px;
  }
  #howitworks .swiper{
    max-height: 400px;
  }
}
@media screen and (max-width:999px){
  #howitworks .slider-imge-div{
    max-height: 316px;
    width: 640px;
  }
  #howitworks .swiper{
    max-height: 400px;
  }
}

@media screen and (max-width: 599px) {
  #howitworks .wsub{
    padding-bottom:40px;
  }
  #howitworks .kkldjfkldjjkgfkgk{
    padding-bottom: 2rem;
  }
  .slider-imge-div{
    height: 100%;
    width: 100%;
    max-height: 270px;
  }
  .swiper-wrapper{
    height: 344px;
  }
  #howitworks .swiper {
    max-height: 350px;
}
  .swiper-button-next{
    display: none;
  }
  .swiper-button-prev{
    display: none;
  }

  #howitworks .howitworks-title-group {
    padding-top: 13px;
  }
  #howitworks .howitworks-title-group .dtitle {
    font-size: 26px;
    letter-spacing: 0;
    margin-bottom: 0;
  }
  #howitworks .howitworks-title-group .wsub {
    font-size: 18px;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  #howitworks .three-simple-step {
    font-size: 20px;
  }
  #howitworks .left-dot-howitworks {
    width: 106px;
    height: 30px;
  }
  #howitworks .right-dot-howitworks {
    width: 106px;
    height: 30px;

    top: 46px;
  }
  #howitworks-greencoloricon {
    height: 25px;
    width: 25px;
  }

  #howitworks .straight-line {
    display: none;
  }
  #howitworks.vertical-line {
    display: flex !important;
    justify-content: center;
    align-items: center;
    border-left: 1px solid green;
    height: 30px;
    margin: 0 auto;
    width: 0;
  }
  #howitworks .uifkdjfkd {
    justify-content: center;
  }
  #howitworks .workitme-h4 {
    font-size: 18px;
  }
  #howitworks .swiper-slide {
    max-height: 350px;
  }
  #howitworks .vertical-line {
    display: flex !important;
    justify-content: center;
    align-items: center;
    border-left: 1px solid green;
    height: 30px;
    margin: 0 auto;
    width: 0;
  }
  #howitworks .uifkdjfkd {
    justify-content: center;
  }
}

@media screen and (max-width:768px){
  #howitworks .slider-imge-div{
    max-height: 266px;
  }
  #howitworks .swiper-slide{
    max-height: 240px;
  }
  #howitworks .swiper{
    max-height: 260px;
  }
}
@media screen and (max-width:400px){
  .slider-imge-div{
    max-height: 208px;
  }
  #howitworks .swiper-slide{
    max-height: 220px;
  }
  #howitworks .swiper {
    max-height: 250px;
}
}
@media screen and (max-width:499px) {
  #howitworks .slider-imge-div{
    max-height: 243px;
  } 
}
@media screen and (max-width:531px) {
  #howitworks .slider-imge-div{
    max-height: 262px;
  } 
}
@media screen and (max-width:392px) {
  #howitworks .slider-imge-div{
    max-height: 184px;
  }
}
@media screen and (max-width:392px) {
  #howitworks .slider-imge-div{
    max-height: 184px;
  }
}

@media screen and (max-width:512px){
  #howitworks .slider-imge-div{
    max-height: 184px;
  }
}
@media screen and (max-width:415px) {
  #howitworks .slider-imge-div{
    max-height: 184px;
  }
}
.swiper-button-prev, .swiper-rtl .swiper-button-next{
  left:60px
}
.swiper-button-next, .swiper-rtl .swiper-button-prev{
  right: 60px;
}
@media screen and (max-width:1200px){
  .swiper-button-prev, .swiper-rtl .swiper-button-next{
    left:15px
  }
  .swiper-button-next, .swiper-rtl .swiper-button-prev{
    right: 15px;
  }
}
@media only screen and (min-width: 200px) and (max-width:991px){
  #howitworks .padding3rem{
    padding-bottom: 40px;
  }
}
@media screen and (max-width:411px){
  #howitworks .howitworks-title-group .dtitle{
    font-size: 26px;
  }
  #howitworks .left-dot-howitworks {
    width: 94px;
  }
  #howitworks .right-dot-howitworks{
    width: 94px;
  }
}

#howitworks .swiper-button-next:after {
  color: white;
  background-color: #0f9b51;
}

#howitworks .swiper-button-prev:after {
  color: white;
  background-color: #0f9b51;
}