/* Absolute Center Spinner */

/* Animation */

.spinner-div {
  height: 100px;
  width: 100px;
  margin: 0 auto;
  position: relative;
  top: 50%;
}

.progress-bar-div{
  margin: 0 auto;
  position: relative;
  top: 50%;
  
}
.progress-bar-div > .bg-success{
  background-color: #0f9b51 !important;
}

.progress-bar-div .text {
  font-family: "open sans";
  font-size: 14px;
  color: white;
}
.loading-text {
  color: white;
  z-index: 9999;
  white-space: nowrap;
  position: absolute;
  top: 103%;
  left: -4rem;
  font-size: 18px;
  font-weight: bold;
  font-family: "open sans";
}
img {
  height: 100%;
  width: 100%;
}

.rotate {
  animation: rotation 1s infinite linear;
}
.spinner-overlay {
  background: #00000080;
  position: fixed;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999999999999999999;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
