.visuallyHidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.hamburger {
  margin: 0 auto;
  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;
}

.hamburger .bar {
  padding: 0;
  width: 30px;
  height: 4px;
  background-color: #0f9b51;
  display: block;
  border-radius: 4px;
  transition: all 0.4s ease-in-out;
  position: absolute;
}

.bar1 {
  top: 0;
}

.bar2,
.bar3 {
  top: 13.5px;
}

.bar3 {
  right: 0;
}

.bar4 {
  bottom: 0;
}

.hamburger4 .bar4 {
  top: 13.5px;
}

.hamburger4 .bar5 {
  bottom: 0px;
}

.hamburger4 .bar {
  transition: all 0.4s ease-in-out, transform 0.4s ease-in-out 0.4s;
}

.hamburger4 .bar2 {
  width: 1px;
  transform: rotate(90deg);
  left: 13.5px;
}

.hamburger4 .bar3 {
  width: 1px;
  left: 13.5px;
}

.checkbox4:checked + label > .hamburger4 > .bar1 {
  top: 13.5px;
  background-color: transparent;
}

.checkbox4:checked + label > .hamburger4 > .bar2 {
  left: 0px;
  width: 30px;
  transform: rotate(45deg);
}

.checkbox4:checked + label > .hamburger4 > .bar3 {
  left: 0;
  width: 30px;
  transform: rotate(-45deg);
}

.checkbox4:checked + label > .hamburger4 > .bar4 {
  background-color: transparent;
}

.checkbox4:checked + label > .hamburger4 > .bar5 {
  bottom: 13.5px;
  background-color: transparent;
}

.activeNavLink {
  border-bottom: 2.5px solid #0f9b51;
}

.boxShadow {
  box-shadow: 3px 3px 10px 4px rgb(0 0 0 / 11%);
}
.boxShadow1 {
  box-shadow: 3px 3px 10px 4px rgb(0 0 0 / 11%);
}
.profile-dropddd {
  display: flex;
  justify-content: center;
  align-items: center;
}
#dropdown-basic-company {
  display: flex;
  align-items: center;
  color: black;
  font-size: var(--1400pxFontSize);
  text-decoration: none;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  font-weight: 700;
}
#dropdown-basic-company svg:active {
  outline: none;
}

/* #dropdown-basic-company::after {
  display: none;
} */
#dropdown-basic-company:active {
  box-shadow: none !important;
  background-color: transparent !important;
}
#dropdown-basic-company:focus {
  box-shadow: none !important;
}

#dropdown-basic svg {
  font-size: 38px;
  color: grey;
}
#dropdown-basic svg:active {
  outline: none;
}
#dropdown-basic::after {
  display: none;
}
#dropdown-basic:active:not(.__dont_hide) {
  box-shadow: none !important;
  background-color: transparent !important;
}
#dropdown-basic:focus {
  box-shadow: none !important;
}
.dropdown-item.bottom-border {
  border-bottom: 1px solid #9f9898;
}
.dropdown-item {
  padding: 8px 20px;
}
.dropdown-item:active {
  background-color: transparent;
  color: black;
}

.dropdown-item .drop-menu-name {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}
.dropdown-item .drop-menu-name_with_user_name {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  white-space: break-spaces;
}

.dropdown-item svg {
  font-size: 30px;
  color: grey;
}
.dropdown-menu {
  padding: 0;
}
#mobile-dropdown-basic-company {
  color: black;
  font-size: var(--1200pxFontSize);
  text-decoration: none;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
/* #mobile-dropdown-basic-company svg:active {
  outline: none;
}
#mobile-dropdown-basic-company::after {
  display: none;
} */
#mobile-dropdown-basic-company:active {
  box-shadow: none !important;
  background-color: transparent !important;
}
#mobile-dropdown-basic-company:focus {
  box-shadow: none !important;
}

#dropdown-basic-company::before{
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #0f9b51;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
#dropdown-basic-company:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.vakil_main_menu_with_buttonn{
  color: #1F2F5D;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: bolder;
  margin-left:10px;
}