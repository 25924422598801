:root {
  --buttonColor: #0f9b51;
}

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.subContainer1 {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;
}

.subContainer2 {
  text-align: center;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.subContainer2 a {
  padding: 7px 17px;
  background-color: var(--buttonColor);
  width: fit-content;
  font-size: 1.8rem;
  color: white !important;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  border: 3px solid #0f9b51;
}

.des {
  font-family: "Open Sans", sans-serif;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.des p {
  text-align: left;
}
.description {
  font-family: "Open Sans", sans-serif;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.des h1 {
  font-family: inherit;
  margin: 0;
  font-size: 5rem;
  text-transform: none;
  color: inherit;
  text-decoration: 2px solid blue;
  font-weight: 900;
  /* text-shadow: 0.5px 0.5px black, -0.5px -0.5px 0 black, 0.5px -0.5px 0 black,
    -0.5px 0.5px 0 black; */
}

.des p {
  font-family: inherit;
  font-weight: 600;
  margin: 0;
  margin-top: 8px;
  font-size: 2.8rem;
}

.media img {
  height: 600px;
}

@media only screen and (max-width: 1400px) {
  .des h1 {
    font-size: 5.5rem;
  }
  .des p {
    font-size: 2rem;
    letter-spacing: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .des h1 {
    font-size: 5rem;
  }
  .des p {
    font-size: 2rem;
    letter-spacing: 0;
  }
  .media {
    width: 50%;
  }
  .media img {
    height: 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 992px) {
  .des h1 {
    font-size: 4rem;
  }
  .des p {
    font-size: 1.8rem;
  }
  .subContainer1 {
    display: flex;
    flex-direction: column;
    padding: 0;
    justify-content: space-between;
  }
  .des {
    align-items: center;
  }
  .subContainer2 {
    margin-top: 0;
  }
  .media {
    width: 56%;
    min-width: 520px;
  }
}

@media only screen and (max-width: 768px) {
  .des h1 {
    font-size: 3.6rem;
    padding-top: 20px;
    /* text-shadow: 0.2px 0.2px black, -0.2px -0.2px 0 black, 0.2px -0.2px 0 black,
      -0.2px 0.2px 0 black; */
  }
  .des p {
    font-size: 1.6rem;
  }
  .media {
    width: 70%;
    min-width: 500px;
  }
}
@media only screen and (max-width: 768px) {
  .imagediv {
    justify-content: center !important;
  }
}

@media only screen and (max-width: 600px) {
  .navbar {
    padding: 15px 5px 15px 17px !important;
  }
  .description {
    margin: 0 10px;
    text-align: center;
  }
  .des h1 {
    font-size: 3rem;
    text-align: center;
  }
  .des p {
    margin-top: 20px;
  }
  .media {
    min-width: 90%;
    max-width: 90%;
  }
  .des p {
    text-align: center;
  }
  .imagediv {
    justify-content: center !important;
  }
}

#learnmore {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 17px;
  background-color: var(--buttonColor);
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.3rem;
  color: white !important;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  border: 3px solid #0f9b51;
}

#learnmore:hover {
  background-color: #d0ebdc;
  color: black !important;
}
.mobilemessage {
  font-size: 11.5px;
  color: #0f9b51;
  background-color: #e8fae8;
  border-radius: 4px;
  padding-left: 9px;
  padding-right: 8px;
  margin-bottom: 4px;
}
.imagediv {
  justify-content: flex-end;
}
.button {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 7px 13px;
  /* background-color: var(--buttonColor); */
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.3rem;
  /* color: white !important; */
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  /* border: 3px solid #0f9b51; */
  /*  */
  display: flex;
  align-items: center;
  /* padding: 18px 14px; */
  border: 3px solid var(--buttonColor);
  border-radius: 10px;
  /* height: 36px; */
  /* margin-right: 14px; */
  color: var(--navColor);
  /* font-size: var(--1200pxFontSize); */
  font-weight: 700;
}

.button:hover {
  background-color: var(--secondaryColor);
  cursor: pointer;
}
.actionbuttons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  gap: 20px;
}
/* .rightImage{
  position: absolute;
  right: 0;
} */
.addImage{
  position: relative;
  /* min-height: 600px; */
}
@media only screen and (min-width: 200px) and (max-width:768px){
  /* .rightImage{
    position: relative;
    height: 330px;
  } */
  .mainheaderimage{
    height: 435px!important;
    min-height: 435px!important;
  }
}