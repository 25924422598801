.message-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .message-container .back-to-home {
    margin-top: 10px;
    background-color: #0f9b51;
    display: flex;
    align-items: center;
    padding: 7px 23px;
    border: 3px solid #0f9b51 !important;
    border-radius: 10px;
    margin-right: 14px;
    font-size: 1.4rem;
    font-weight: 700;
    color: white;
  }
  
  .message-container .back-to-home:hover {
    background-color: #d0ebdc;
    color: black;
  }
  .signup-final-step {
    display: flex;
    justify-self: center;
    align-items: center;
    width: 60%;
    max-width: 450px;
    flex-direction: column;
    box-shadow: #0f9b51 0px 1px 2px 0px, #0f9b51 0px 1px 3px 1px;
    padding: 30px;
    border-radius: 12px;
  }
  .congrats-message {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .congrats-message p {
    font-family: "open sans";
    font-size: 16px;
    white-space: nowrap;
    margin-bottom: 0;
  }
  .whiteback__drag_coming_soon{
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    width: 80%;
    align-items: center;
    margin: 0 auto;
    min-height: 400px;
    flex-direction: column;
  }