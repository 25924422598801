.onlyformargin {
  background-image: url("../../assets/green-background.jpg");

  background-repeat: repeat-y;
  background-position: center;
  background-size: cover;
  height: auto;
  min-height: 100%;
}

.containerParent {
  /* min-height: 90vh; */
  height: auto;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* margin-bottom: 20px; */
  /* background-image: url("../../assets/green-background.jpg");
  background-repeat: repeat-y;
  background-position: center;
  background-size: cover; */
  font-family: "open sans";
}
.containerParent input {
  font-size: 14px;
  font-family: "open sans";
}
.containerParent select {
  font-size: 14px;
  font-family: "open sans";
}

.uploadCard {
  /* margin-top: 50px; */
  /* margin-bottom: 20px; */
  justify-content: center;
  height: auto;
  width: fit-content;
  border-radius: 10px;
  background-color: white;
  box-shadow: #0f9b51 0px 1px 2px 0px, #0f9b51 0px 1px 3px 1px;
}

.uploadCardBody {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.uploadDocTitle {
  text-align: center;
  margin-top: 0;
  font-size: 30px;
}
.buttonContainer {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonContainer Button {
  font-size: 16px;
}

.buttonContainer .nextButton {
  float: right;
}

.buttonContainer .submitButton {
  float: right;
}

/* h2 {
  font-family: "Poppins", sans-serif;
  margin-top: 0rem;
  font-weight: bolder;
  margin-bottom: 40px;
} */

@media only screen and (min-device-width: 540px) and (orientation: landscape) {
  .uploadCard {
    /* margin-top: 30px; */
    width: 100%;
    margin-bottom: 3rem;
    height: auto;
  }
  h2 {
    margin-top: 3rem;
  }
  .uploadCardBody {
    padding: 7rem;
    padding-top: 4rem;
    height: 100%;
  }
}
@media only screen and (min-width: 540px) and (orientation: portrait) {
  .uploadCard {
    /* margin-top: 30px; */
    width: 100%;
    height: 80%;
    /* margin-bottom: 3rem; */
  }
  h2 {
    margin-top: 3rem;
  }
  .uploadCardBody {
    padding: 7rem;
    padding-top: 4rem;
    height: 100%;
  }
}

/* iPhone Xr */

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape) {
  .uploadCard {
    /* margin-top: 30px; */
    width: 100%;
    height: auto;
    margin-bottom: 3rem;
  }
  h2 {
    margin-top: 3rem;
  }
  .uploadCardBody {
    padding: 7rem;
    padding-top: 4rem;
    height: 100%;
  }
}

.formproceedgreenbutton {
  /* margin-top: 10px; */
  background-color: #0f9b51;
  display: flex;
  align-items: center;
  padding: 5px 25px;
  border: 3px solid #0f9b51 !important;
  border-radius: 10px;
  margin-right: 14px;
  font-size: 1.4rem;
  font-weight: 700;
  color: white;
  margin: 0;
}
.prevbtn {
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding: 5px 25px;
  border-radius: 10px;
  margin-right: 14px;
  font-size: 1.4rem;
  font-weight: 700;
  color: black;
  background-color: #d6d6d9;
  border: none;
  font-family: "open sans";
  margin: 0;
  border: 1px solid #d6d6d9;
}
.prevbtn:hover {
  color: #0f9b51;
  border: 1px solid #0f9b51;
  background-color: #d6d6d9;
}
.back-link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 23px;
  margin-top: 12px;
}

.proceedhover:hover {
  background-color: #d0ebdc;
  color: black;
}
.disableButton {
  opacity: 0.5;
}

.formproceedgreenbutton:hover {
}
