.main-numbers{
    display: flex;
    justify-content: space-around;
    background-color: #0f9b51;
    padding: 3rem;
    align-items: center;
}
.num-cont{
    display: flex;
    flex-direction: column;
    /* font-size: 20px; */
    color: white;
}
.lk-numbers{
    
    font-size: 50px;
    font-weight: 700;
    text-align: center;

}
.lk-num-text{
    font-size: 20px;
    font-weight: 400;;
}
@media screen and (max-width: 599px) {
    .lk-numbers{
        font-size: 35px;
    }
    .lk-num-text{
        font-size: 16px;
    }
}