:root {
  --navColor: rgb(43, 43, 43);
  --buttonColor: #0f9b51;
  --secondaryColor: #d0ebdc;
  --1400pxFontSize: 1.6rem;
  --1200pxFontSize: 1.4rem;
}

.navbar {
  padding: 26px 32px 26px 32px;
  transition: all 0.6s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--navColor);
  font-family: "Open Sans", sans-serif;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  width: 100%;
  background-color: white;
  z-index: 99999;
}

.logoDivAnchor {
  padding: 0;
  cursor: pointer;
  text-decoration: none;
  color: var(--navColor);
}

.logoDivAnchor:hover {
  color: var(--navColor);
}

.logoContainer {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  justify-content: flex-start;
}

.logo {
  height: 42px;
  width: 42px;
  margin-right: 10px;
}

.title {
  font-size: 2rem;
  color: var(--navColor);
}

.navSections {
  width: 36%;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  font-weight: 700;
}

.navSections li {
  display: inline;
  cursor: pointer;
}

.navSections li a {
  color: black;
  color: black;
  font-size: var(--1400pxFontSize);
  text-decoration: none;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  font-weight: 700;
}

.navSections li a::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: var(--buttonColor);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.navSections li a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}


.navActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navActions .button {
  display: flex;
  align-items: center;
  padding: 18px 14px;
  border: 3px solid var(--buttonColor);
  border-radius: 10px;
  height: 36px;
  margin-right: 14px;
  color: var(--navColor);
  font-size: var(--1200pxFontSize);
  font-weight: 700;
}

.navActions .button:hover {
  background-color: var(--secondaryColor);
  cursor: pointer;
}

.navActions .signUp {
  background-color: var(--buttonColor);
  color: white;
}

.navActions .signUp:hover {
  background-color: var(--secondaryColor);
  color: black;
}

.navMenuButton {
  margin-right: 14px;
  margin-bottom: 4px;
}

.smallNavSections {
  /* position: absolute; */
  /* margin-top: 270px; */
  /* margin-left: -32px; */
  width: 100vw;
  position: fixed;
  height: 100vh;

  background-color: white;
  padding: 10px;
  /* box-shadow: 0 2px 6px black; */
  /* transform-origin: top; */
  /* animation-name: navbarSlide; */
  /* animation-timing-function: ease-out; */
  /* animation-duration: 0.4s; */
  /* animation-fill-mode: forwards; */
  /* transform: translateY(0px) translateX(0px); */
    /* transition: transform 400ms ease 0s; */


}
.withani{
  color: black;
    font-size: var(--1200pxFontSize);
    text-decoration: none;
    display: inline-block;
    position: relative;
    vertical-align: middle;
  cursor: pointer;
  transform-origin: top;
  animation-name: navbarSlide;
  animation-timing-function: ease-out;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  transform: translateY(0px) translateX(0px);
    transition: transform 400ms ease 0s;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.uldiv {
  position: absolute;
  top: 76px;
  left: 1px;
  padding-top: 10px;
}
.uldiv ul {
  margin: 0;
  padding: 0;
}
@keyframes navbarSlide {
  from {
    transform: scale(1, 0);
    opacity: 0;
  }
  to {
    transform: scale(1, 1);
    opacity: 1;
  }
}

.smallNavSections li {
  padding: 10px;
  text-align: center;
  list-style: none;
}

.smallNavSections li a {
  color: black;
  font-size: var(--1200pxFontSize);
  text-decoration: none;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.smallNavSections li a::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: var(--buttonColor);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.smallNavSections li a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@media only screen and (min-width: 1400px) {
  .navSections {
    width: 31%;
  }
}

@media only screen and (min-width: 768px) {
  .navMenuButton,
  .smallNavSections {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .navSections {
    font-size: var(--1200pxFontSize);
    width: 430px;
    margin-right: 16px;
  }
  .navActions {
    display: none;
  }
  .title {
    font-size: 1.8rem;
  }
  .logo {
    height: 36px;
    width: 36px;
  }
}
@media screen and (max-width: 991px) {
  .navSections {
    display: none;
  }
  .smallNavSections {
    display: block;
  }
  .navMenuButton {
    display: block;
  }
  .navbar {
    padding: 26px 5px 26px 17px;
  }
  /* .navMenuButton,
  .smallNavSections {
    display: none;
  } */
}
.connectwithus{
  font-family: "Open Sans", sans-serif;
font-size: 12px;
}
.werarehiringtext{
  background-color: #0f9b51;
    color: white;
    padding: 7px;
    border-radius: 18px;
    font-size: 10px;
    margin-left: 10px;
    font-family: "Open Sans", sans-serif;

}
.sociallinkssmallnavli{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0f9b51;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
}
#sociallinkssmallnavlia {
  font-size: 16px;
  color: white;
}
/* @media only screen and (max-width: 768px) {
  .navSections {
    display: none;
  }
} */
