.image-container {
    height: 95px;
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.image {
    object-fit: contain;
    margin: auto;
    width: 208px !important;
}

.sp-image img{
    width: 250px !important;
}