.testimonial {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* min-height: 200px;
    border: 1px solid black; */
}

.testimonial-details {
    grid-column-gap: 40px;
    text-align: left;
    grid-template-rows: auto;
    grid-template-columns: auto 1fr;
    grid-auto-columns: 1fr;
    align-items: center;
    max-width: 963px;
    display: flex;
}

.testimonial-image {
    object-fit: cover;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    position: relative;
}

.photo-wrapper {
    flex: none;
    width: 299px;
    height: 323px;
    position: relative;
}

.testimonial-text-wrapper {
    font-size: 19px;
    flex-direction: column;
    justify-content: center;
    max-width: 55ch;
    height: 100%;
    display: flex;
}

.testimonial-text {
    /* color: var(--dark-slate-blue-primary); */
    font-family: "open sans";
    font-size: 19px;
    font-weight: 400;
    line-height: 32px;
}

.testimonial-name-and-position-wrapper {
    flex-direction: column;
    display: flex;
}

.p-n_wrapper {
    grid-column-gap: 4px;
    font-family: "open sans";
    font-size: 14px;
    align-items: center;
    margin-top: 16px;
    display: flex;
}

.position-wrapper {
    font-size: var(--font-size--para-small-desk);
    display: flex;
}

.tt-divider {
    margin-left: 5px;
    margin-right: 5px;
}

@media screen and (max-width: 991px) {
    .testimonial-details {
        grid-row-gap: 32px;
        flex-direction: column;
        grid-template-columns: 1fr;
        align-items: center;
        max-width: none;
    }
}

@media screen and (max-width: 479px){
    .testimonial-details {
        background-image: none;
        height: auto;
    }

    .photo-wrapper {
        width: 100%;
        max-width: 272px;
        height: 272px;
    }

    .testimonial-text-wrapper {
        border-radius: 0;
        padding-left: 16px;
        padding-right: 16px;
    }
}

@media screen and (max-width: 767px){

}