.process_report_page_full_height{
    background-image: url("../../assets/green-background.jpg");
    background-size: cover;
    height: auto;
    min-height: 100%;
    width: 100%;
    padding-top: 100px;
}
.process_report_page_full_height_message_container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 100px);
    justify-content: center;
    align-items: center;

}
.process_report_page_full_height_message_container .check-fill-icon svg{
    font-size: 75px;
    color: #0f9b51;
}
.process_report_page_full_height_message_container .message_section{
    display: flex;
    justify-self: center;
    align-items: center;
    width: 60%;
    max-width: 450px;
    flex-direction: column;
    /* box-shadow: #0f9b51 0px 1px 2px 0px, #0f9b51 0px 1px 3px 1px; */
    padding: 30px;
    border-radius: 12px;
    background-color: white;
}
.process_report_total_files_count{
    font-family: "open sans";
    font-size: 16px;
    /* white-space: nowrap; */
    margin-bottom: 0;
    font-weight: 600;
    margin-bottom: 10px;
    margin-bottom: 10px;
}