#whyus {
  position: relative;
  height: auto;
  width: 100%;
  padding-top: 50px;
}
.onezerothree{
  width: 103%;
}
#whyus .left-dot-whyus {
  max-height: 100px;
  position: absolute;
  left: 0;
  width: 450px;
}
#whyus .right-dot-whyus {
  max-height: 100px;
  position: absolute;
  right: 0;
  width: 450px;
  top: 55px;
}

#whyus img {
  height: 100%;
  width: 100%;
}
.whyus-title-group {
  display: flex;
  justify-content: center;
  padding-top: 35px;
  flex-direction: column;
  width: 100%;
  align-items: center;
  font-family: "open sans";
}
.whyus-title-group .dtitle {
  font-size: 45px;
  font-weight: 700;
  font-family: "open sans";
  letter-spacing: 1px;
  line-height: 40px;
  font-family: "open sans";
  padding-bottom: 65px;
}
.wsub {
  font-size: 25px;
  letter-spacing: 1px;
}
#greencoloricon {
  height: 40px;
  width: 40px;
  color: #0f9b51;
}
#whyuicon {
  border-radius: 50%;
  border: 2px solid #0f9b51;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  max-height: 70px;
  max-width: 70px;
  /* flex: 0 0 17%; */
}
.whyuscontentcontainer {
  border-radius: 20px;
  box-shadow: #0f9b51 0px 1px 2px 0px, #0f9b51 0px 1px 3px 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  gap: 2rem;
  font-family: "open sans";
  transition: transform 0.5s ease;
  width: 90%;
}
.why-t {
  font-size: 20px;
  font-weight: bold;
  font-family: "open sans";
}
.why-sub {
  font-size: 20px;
  font-weight: 400;
  font-family: "open sans";
}
.whyusptb {
  /* padding: 6rem 0; */
}
.whyuscontentcontainer:hover {
  transform: scale(1.1);
}
@media only screen and (min-width: 1199px) and (max-width: 1229px) {
  #whyus .left-dot-whyus {
    width: 350px;
  }
  #whyus .right-dot-whyus {
    width: 250px;
    top: 46px;
  }
  .workitme-h4 {
    font-size: 24px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1199px) {
  #whyus .left-dot-whyus {
    width: 300px;
  }
  #whyus .right-dot-whyus {
    width: 300px;
    top: 46px;
  }
  .workitme-h4 {
    font-size: 20px;
  }
}
@media only screen and (min-width: 599px) and (max-width: 992px) {
  #whyus-greencoloricon {
    height: 35px;
    width: 35px;
  }
  .whyus-title-group {
    padding-top: 13px;
  }
  .whyus-title-group .dtitle {
    font-size: 30px;
    /* letter-spacing: 0; */
    /* margin-bottom: 0; */
  }

  #whyus .left-dot-whyus {
    width: 200px;
  }
  #whyus .right-dot-whyus {
    width: 200px;
    top: 46px;
  }
}
@media screen and (max-width: 599px) {
  .whyuscontentcontainer{
    width: 90%;
  }
  .whyuscontentcontainer:hover {
    transform: scale(1);
  }
  #whyuicon {
    height: 50px;
    width: 50px;
  }
  .whyus-title-group {
    padding-top: 13px;
  }
  .whyus-title-group .dtitle {
    font-size: 28px;
    letter-spacing: 0;
    margin-bottom: 0;
  }
  .d-title-group .wsub {
    font-size: 15px;
  }

  #whyus .left-dot-whyus {
    width: 125px;
    height: 30px;

  }
  #whyus .right-dot-whyus {
    width: 125px;
    height: 30px;

    top: 46px;
  }
  #whyus-greencoloricon {
    height: 25px;
    width: 25px;
  }
  .why-t {
    font-size: 18px;
  }
  .why-sub {
    font-size: 18px;
  }
}

.nextpad {
  padding-bottom: 2rem;
}
.padb37{
  padding-bottom: 37px;
}
.whyuspadtop{
  padding-top: 55px;
}
.whyuspadbottom{
  padding-bottom: 35px;
}
.lkgapbetweencontainerwhyus{
  margin-bottom: 55px;
}
@media only screen and (min-width: 200px) and (max-width:991px){
  .padb37{
    padding-bottom: 25px;
  }
.whyuspadbottom{
  padding-bottom: 15px;
}
.whyus-title-group .dtitle{
  padding-bottom: 10px;
}
.whyuspadtop{
  padding-top: 10px;
}
#whyus{
  padding-bottom: 25px;
}
#whyus.lkgapbetweencontainerwhyus{
  margin-bottom: 5px;
}

}
@media screen and (max-width:411px){
  #whyus .left-dot-whyus{
    width: 100px;
  }
  #whyus .right-dot-whyus{
    width: 100px;
  }
}

.react-multiple-carousel__arrow {
  background: #0f9b51;
  border: 1px solid #0f9b51;
}

.react-multiple-carousel__arrow:hover {
  background: #0f9b51;
  border: 1px solid #0f9b51;
}


.react-multi-carousel-dot--active button {
  background: #0f9b51 !important;
}

.react-multi-carousel-dot button {
  height: 8px;
  width: 8px;
  border-width: 0px;
}