#whatwedo {
  position: relative;
  height: auto;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 60px;
}
#whatwedo .left-dot-whatwedo {
  /* background-image: url('../../../assets/greendot.svg');
  background-size: contain;
  background-repeat: no-repeat; */

  max-height: 100px;
  position: absolute;
  left: 0;
  width: 450px;
}
#whatwedo .right-dot-whatwedo {
  /* background-image: url('../../../assets/greendot.svg'); 
background-size: contain;
background-repeat: no-repeat; */

  max-height: 100px;
  position: absolute;
  right: 0;
  width: 450px;
  top: 55px;
}

#whatwedo img {
  height: 100%;
  width: 100%;
}
.whatwedon-title-group {
  display: flex;
  justify-content: center;
  padding-top: 35px;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.whatwedon-title-group .dtitle {
  font-size: 45px;
  font-weight: 700;
  font-family: "open sans";
  letter-spacing: 1px;
  line-height: 70px;
}
#whatwedo .wsub {
  font-size: 25px;
  letter-spacing: 1px;
  font-family: "open sans";
  padding-bottom: 16px;
}
#whatwedo .cn-st {
  padding-top: 50px;
  padding-bottom: 50px;
}
.newwhatwedo-main-content {
  background-color: #0f9b51;
  border-radius: 10px;
  padding: 3rem 2rem;
  font-family: "open sans";
}
#whatwedo .newwhatwedo-main-content .p-text-content {
  font-family: "open sans";
  color: white;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 1px;
  padding-bottom: 10px;
  font-family: "open sans";
}
#whatwedo .whaticon {
  border-radius: 50%;
  border: 2px solid #0f9b51;
  height: 55px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  flex: 0 0 auto;
  max-height: 70px;
  max-width: 70px;
}
#whatwedo .whaticon-right {
  border-radius: 50%;
  border: 2px solid #0f9b51;
  height: 55px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  flex: 0 0 auto;
  max-height: 70px;
  max-width: 70px;
}
#wahtwedo-greencoloricon {
  height: 35px;
  width: 35px;
  color: #0f9b51;
}
#whatwedo .ulwit {
  list-style: none;
  font-family: "open sans";
}
#whatwedo .listwiticontext1 {
  display: flex;
  align-items: center;
  padding-bottom: 5rem;
  font-family: "open sans";
  justify-content: flex-start;
}
#whatwedo .listwiticontext {
  display: flex;
  align-items: center;
  padding-bottom: 5rem;
  font-family: "open sans";
  justify-content: flex-end;
}
#whatwedo .ntwi {
  font-size: 25px;
  font-weight: 400;
  font-family: "open sans";
  /* padding-left: 4px; */
}
#wahtwedo-greencoloricon path {
  stroke: #0f9b51 !important;
}
#whatwedo .btwi {
  font-weight: bold;
  font-size: 25px;
  font-family: "open sans";
}
#whatwedo .firstrempadding{
  padding-top:5rem;
}
#whatwedo .secondrempadding{
  padding-top: 5rem;
}
@media only screen and (min-width: 200px) and (max-width:991px){
  #whatwedo .firstrempadding{
    padding-top:0rem;
  }
  #whatwedo .secondrempadding{
    padding-top: 3rem;
  }
  #whatwedo .lastpaddingformobile{
    padding-bottom: 3rem;
  }
  #whatwedo .lastpadding{
    padding-bottom: 0!important;
  }
  #whatwedo{
    padding-bottom: 40px;
  }
  #howitworks .wsub{
    padding-top: 0px;
  }
}
@media only screen and (min-width: 1199px) and (max-width: 1229px) {
  #whatwedo .left-dot-whatwedo {
    width: 350px;
  }
  #whatwedo .right-dot-whatwedo {
    width: 350px;
    top: 46px;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1199px) {
  #whatwedo .left-dot-whatwedo {
    width: 300px;
  }
  #whatwedo .right-dot-whatwedo {
    width: 300px;
    top: 46px;
  }
}
@media only screen and (min-width: 599px) and (max-width: 992px) {
  #whatwedo .cn-st{
    padding-top: 30px;
  }
  #whatwedo .ntwi {
    font-size: 15px;
  }
  #whatwedo .btwi {
    font-size: 15px;
  }
  #whatwedo .p-text-content {
    font-size: 14px;
    line-height: 25px;
  }
  #whatwedo .whaticon {
    height: 50px;
    width: 50px;
    flex: 0 0 auto;
  }
  #whatwedo .whaticon-right {
    height: 50px;
    width: 50px;
    flex: 0 0 auto;
  }
  #wahtwedo-greencoloricon {
    height: 35px;
    width: 35px;
  }
  #whatwedo .whatwedon-title-group {
    padding-top: 13px;
  }
  #whatwedo .whatwedon-title-group .dtitle {
    font-size: 30px;
    /* letter-spacing: 0; */
    /* margin-bottom: 0; */
  }
  #whatwedo .whatwedon-title-group .wsub {
    font-size: 20px;
  }
  #whatwedo .left-dot-whatwedo {
    width: 200px;
  }
  #whatwedo .right-dot-whatwedo {
    width: 200px;
    top: 46px;
  }
}

/* 
    Small Screen Size
*/
@media screen and (max-width: 599px) {
  #whatwedo .cn-st{
    padding-top: 20px;
  }
  #whatwedo .btwi {
    font-size: 18px;
  }
  #whatwedo .ntwi {
    font-size: 18px;
  }
  #whatwedo .right-ulwit {
    padding-right: 2rem;
  }
  #whatwedo .whatwedon-title-group {
    padding-top: 13px;
  }
  #whatwedo .whatwedon-title-group .dtitle {
    font-size: 26px;
    letter-spacing: 0;
    margin-bottom: 0;
  }
  #whatwedo .whatwedon-title-group .wsub {
    font-size: 18px;
  }
  #whatwedo .left-dot-whatwedo {
    width: 115px;
    height: 30px;
  }
  #whatwedo .right-dot-whatwedo {
    width: 115px;
    top: 46px;
    height: 30px;
  }
  #whatwedo .whaticon {
    height: 40px;
    width: 40px;
    flex: 0 0 auto;
  }
  #whatwedo .whaticon-right {
    height: 40px;
    width: 40px;
    flex: 0 0 auto;
  }
  #wahtwedo-greencoloricon {
    height: 25px;
    width: 25px;
  }
}

@media screen and (max-width:411px){
  #whatwedo .left-dot-whatwedo{
    width: 100px;
  }
  #whatwedo .right-dot-whatwedo{
    width: 100px;
  }
}
#whatwedo .lkgapbetweencontainer-whatwedo{
  margin-bottom: 33px!important;
}

